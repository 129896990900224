<template>
	<div class="content-box-div">
		
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" :treeCheckStrictly="true" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						@change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>
		
		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="add" icon="plus-circle">
				添加
			</a-button>
		</div>
		
		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="province" slot-scope="text, record">
					<span>{{record.province}} {{record.city}} {{record.district}}</span>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.cdr_id)">
						<template slot="title">
							<p>是否确定删除该配置？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>
		
		<!-- <div class="tabbox">
			<a-tabs type="card">
				<a-tab-pane key="1" tab="日结备注配置">
					<a-spin :spinning="remarkLoading">
						<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }">
							<a-form-item label="备注配置">
								<a-button style="margin-right: 14px;">{{remarkValue || '暂未配置'}}</a-button>
								<a-button type="primary"
									@click="saveRemarkConfig">{{remarkValue ? '重新配置' : '备注配置'}}</a-button>
							</a-form-item>
						</a-form>
					</a-spin>
				</a-tab-pane>
			</a-tabs>
		</div> -->

		<a-modal :dialogStyle="{top: '60px'}" :title="detailData.cdr_id == -1 ? '添加备注配置' : '编辑备注配置'" :visible="remarkvisible" @ok="handleOk" @cancel="handleCancel">
			<div>
				<div class="fo_input">
					<div class="fo_label">客户选择：</div>
					<div class="fo_value">
						<a-tree-select
						    v-model="detailData.corp_id"
						   style="width: 340px;"
						    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						    :tree-data="corpList"
						    placeholder="请选择客户"
							:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
						  >
						  </a-tree-select>
					</div>
				</div>
				<div class="fo_input">
					<div class="fo_label">备注分隔符：</div>
					<div class="fo_value">
						<a-input style="width: 340px;" v-model="detailData.space" placeholder="请输入拼接分隔符" />
					</div>
				</div>
				<div class="fo_input">
					<div class="fo_label">备注勾选：</div>
					<div class="fo_value">
					</div>
				</div>
				<div>
					<div class="check_remark">
						<div v-for="item in remarkList" :key="item.value">
							<a-checkbox :value="item" :checked="item.check" @change="onChange">
								{{item.label}}
							</a-checkbox>
						</div>
					</div>
					<div class="title_remark">已选择：</div>
					<div class="tips" v-if="checkedList.length == 0">请勾选配置</div>
					<div class="list_remark">
						<div class="item_remark" v-for="(item, index) in checkedList" :key="item">
							<span class="index">{{index + 1}}</span>
							<span class="text">{{getRemarkName(item)}}</span>
							<a-icon class="close" @click="deleteRemark(item)" type="close-circle" />
						</div>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	export default {
		data() {
			return {
				remarkValue: '',
				remarkValueList: [],
				lodRemarkObject: {},
				remarkList: [],
				remarkvisible: false,
				checkedList: [],
				remarkLoading: true,
				
				corp_id: null,
				tablePage: 1,
				corpList: [],
				tableData: [],
				tableTotal: 0,
				btnIds: [],
				columns: [
					{
						title: '客户名称',
						dataIndex: 'corp_name',
						key: 'corp_name',
						width: 200,
					},
					{
						title: '备注配置',
						key: 'content',
						dataIndex: 'content',
						scopedSlots: {
							customRender: 'content'
						},
					},
					{
						title: '操作',
						key: 'age6',
						width: 150,
						scopedSlots: {
							customRender: 'age6'
						},
					},
				],
				detailData: {
					cdr_id: -1,
					corp_id: null,
					content: '',
					space: ''
				},
				nameObjects: {}
			}
		},
		mounted() {
			this.getRemarkInit()
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				this.getTable()
			})
		},
		methods: {
			remove(id) {
				http({
					method: 'post',
					url: '/api.config.remark.del/',
					data: {
						cdr_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			add () {
				this.remarkList.forEach((v) => {
					v.check = false
				})
				this.detailData = {
					cdr_id: -1,
					corp_id: null,
					content: '',
					space: ''
				}
				this.checkedList = []
				this.remarkvisible = true
			},
			edit (data) {
				let names = data.content.split(data.space)
				let contents = []
				names.forEach((v) => {
					contents.push(this.nameObjects[v])
				})
				this.checkedList = contents
				this.remarkList.forEach((v) => {
					if (contents.includes(v.value)) {
						v.check = true
					} else {
						v.check = false
					}
				})
				this.detailData = data
				this.remarkvisible = true
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.corp_name = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.config.remark.list/',
					data: {
						page: this.tablePage,
						corp_id: this.corp_id || 0
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			corpChange(data) {
				this.corp_id = data
			},
			
			getRemarkInit () {
				// this.remarkLoading = true
				 http({
				 	method: 'post',
				 	url: '/api.config.remark.params/',
				 	data: {}
				 }).then((res) => {
				 	this.lodRemarkObject = res.data
					let nameObjects = {}
				 	let remarkList = []
				 	for (let key in res.data) {
				 		remarkList.push({
				 			value: key,
				 			label: res.data[key],
				 			check: false
				 		})
						nameObjects[res.data[key]] = key 
				 	}
				 	this.remarkList = remarkList
					this.nameObjects = nameObjects
				 	// http({
				 	// 	method: 'post',
				 	// 	url: '/api.config.remark.corp/',
				 	// 	data: {}
				 	// }).then((resData) => {
				 	// 	let remarkStr = []
				 	// 	if (resData.data) {
				 	// 		remarkStr = resData.data.split(',')
				 	// 	}
				 	// 	let remarkValue = []
				 	// 	let remarkList = []
				 	// 	remarkStr.forEach((item) => {
				 	// 		remarkList.push({
				 	// 			type: item,
				 	// 			name: res.data[item]
				 	// 		})
				 	// 		remarkValue.push(res.data[item])
				 	// 	})
				 	// 	this.remarkValue = remarkValue.join(' - ')
						// this.remarkLoading = false
				 	// })
				 })
			 },
			deleteRemark (value) {
				this.checkedList.splice(this.checkedList.indexOf(value), 1);
				this.remarkList.forEach((v) => {
					if (v.value == value) {
						v.check = false
					}
				})
			},
			getRemarkName(type) {
				return this.lodRemarkObject[type]
			},
			onChange(e) {
				let value = e.target.value.value
				if (this.checkedList.includes(value)) {
					this.checkedList.splice(this.checkedList.indexOf(value), 1);
					this.remarkList.forEach((v) => {
						if (v.value == value) {
							v.check = false
						}
					})
				} else {
					this.remarkList.forEach((v) => {
						if (v.value == value) {
							v.check = true
						}
					})
					this.checkedList.push(value)
				}
			},
			saveRemarkConfig() {
				this.remarkvisible = true
				
			},
			handleOk() {
				let data = {
					corp_id: this.detailData.corp_id,
					space: this.detailData.space,
					content: this.checkedList.join(',')	
				}
				console.log(this.detailData.cdr_id, 'this.detailData.cdr_id')
				if (this.detailData.cdr_id > 0) {
					data['cdr_id'] = this.detailData.cdr_id
				} else {
					data['cdr_id'] = 0
				}
				http({
					method: 'post',
					url: '/api.config.remark.add/',
					data: data 
				}).then((res) => {
					if (res.code == 200) {
						this.getTable()
						this.$message.success('配置成功！');
						this.checkedList = []
						this.remarkvisible = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			handleCancel () {
				this.checkedList = []
				this.remarkvisible = false
			}
		}
	}
</script>

<style scoped lang="less">
	.fo_input {
		display: flex;
		align-items: center;
		height: 40px;
		margin-bottom: 6px;
		.fo_label {
			width: 100px;
			display: flex;
			align-items: center;
			height: 40px;
			&:before {
				width: 3px;
				height: 14px;
				background-color: #3473FF;
				content: "";
				margin-right: 4px;
			}
		}
		.fo_value {
			
		}
	}
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}
	
	.filter-box {
		padding: 20px;
	}
	
	.btns10 {
		margin-left: 30px;
	
		button {
			margin-right: 10px;
		}
	}
	
	.table-box {
		padding: 0px 20px;
	}
	
	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
	.tips {
		opacity: 0.6;
		    font-size: 12px;
		    width: 64px;
		    text-align: center;
	}
	.content-box-div {
		/* min-height: 400px; */
	}

	.tabbox {
		padding: 20px;
	}
	.check_remark {
		display: flex;
		flex-wrap: wrap;
	}
	.check_remark>div {
		width: 48%;
		margin-bottom: 10px;
	}

	.title_remark {
		height: 40px;
		display: flex;
		align-items: center;
		font-weight: 700;
		font-size: 14px;

		&:before {
			content: "";
			width: 4px;
			height: 16px;
			background-color: #3473FF;
			margin-right: 6px;
		}
	}

	.list_remark {
		min-height: 120px;
		padding-left: 10px;
		.item_remark {
			margin-bottom: 4px;
			height: 30px;
			display: flex;
			align-items: center;
			
			&:nth-child(1) {
				.index {
					background-color: #ff1212;
					color: #fff;
				}
			}
			&:nth-child(2) {
				.index {
					background-color: #21c9f6;
					color: #fff;
				}
			}
			&:nth-child(3) {
				.index {
					background-color: #ff7619;
					color: #fff;
				}
			}

			.index {
				width: 20px;
				height: 20px;
				background-color: #e1dada;
				border-radius: 100px;
				margin-right: 6px;
				display: flex;
				color: #333;
				align-items: center;
				justify-content: center;
			}

			.text {
				width: 220px;
			}

			.close {
				cursor: pointer;
			}
		}
	}
</style>