<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input size="default" v-model="number" placeholder="输入设备编号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="设备名称">
					<a-input size="default" v-model="name" placeholder="输入设备名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<a-button type="primary"  v-if="btnIds.includes(139)" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
			<a-button type="primary" v-if="btnIds.includes(140)" @click="close()" icon="pause-circle" style="margin-left: 10px;">
				一键暂停
			</a-button>
			<a-button type="primary" v-if="btnIds.includes(140)" @click="open()" icon="play-square" style="margin-left: 10px;">
				一键启用
			</a-button>
		</div>

		<div class="table-box">
			<a-tabs type="card" @change="tabCallback">
			    <a-tab-pane :key="item.status" :tab="item.name + ' (' + item.value + ')'" v-for="(item, index) in statusList">
			    </a-tab-pane>
			  </a-tabs>
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="isopen" slot-scope="text, record">
					<a-tag color="blue" v-if="record.is_open == 0">
						禁用
					</a-tag>
					<a-tag color="orange" v-if="record.is_open == 1">
						启用
					</a-tag>
				</div>
				<div slot="is_on_used" slot-scope="text, record">
					<a-tag color="blue" v-if="record.is_on_used == 0">
						禁用
					</a-tag>
					<a-tag color="orange" v-if="record.is_on_used == 1">
						启用
					</a-tag>
				</div>
				
				<div slot="age1" slot-scope="text, record">	
					{{ record.vname }} {{ record.version }}
				</div>	
				<div slot="age2" slot-scope="text, record">	
					{{ record.cash_num }}/{{ record.env_max }}/{{ record.cash_max }}
				</div>	
				<div slot="age3" slot-scope="text, record">					
					<span class="btn-span" @click="toOpen(record.id,1)" v-if="record.is_open == 0 && btnIds.includes(140)">
						启用
					</span>				
					<span class="btn-span" @click="toOpen(record.id,0)" v-if="record.is_open == 1 && btnIds.includes(140)">
						禁用
					</span>
					<a-popconfirm v-if="btnIds.includes(142)" placement="top" ok-text="确定" cancel-text="取消" @confirm="clean(record.id)">
						<template slot="title">
							<p>是否确定解绑该设备？</p>
						</template>
						<span class="btn-span">
							解绑
						</span>
					</a-popconfirm>
					<span class="btn-span" v-if="btnIds.includes(140)" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span" v-if="btnIds.includes(143)" @click="editY(record)">
						设置
					</span>
					<a-popconfirm placement="top" v-if="btnIds.includes(141)" ok-text="确定" cancel-text="取消" @confirm="remove(record.id)">
						<template slot="title">
							<p>是否确定删除该设备？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal :title="!form.id ? '添加' :'编辑'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="设备名称" prop="name">
					<a-input v-model="form.name" placeholder="请输入设备名称" />
				</a-form-model-item>
				<a-form-model-item label="设备编号" prop="number">
					<a-input v-model="form.number" placeholder="请输入设备编号" />
				</a-form-model-item>
				<a-form-model-item label="选择客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" @change="chcorpChange" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item v-if="form.corp_id" label="选择网点" prop="node_id">
					<a-select style="width: 100%" v-model="form.node_id" placeholder="请选择网点" >
					   <a-select-option :value="item.node_id" v-for="(item, index) in nodeList" :key="index">
					       {{item.name}}
					   </a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="设备类型" prop="type_id">
					<a-select style="width: 100%" v-model="form.type_id" placeholder="请选择设备类型" >
					   <a-select-option :value="item.type_id" v-for="(item, index) in deviceTypelist" :key="index">
					       {{item.type_name}}
					   </a-select-option>
					</a-select>
				</a-form-model-item>
				
				<a-form-model-item  label="选择锁具" prop="lock_id">
					<a-select style="width: 100%" v-model="form.lock_id" placeholder="请选择锁具" >
					   <a-select-option :value="item.lock_id" v-for="(item, index) in lockList" :key="index">
					       {{item.snumber}}
					   </a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="设备是否启用" prop="is_on_used">
					<a-switch un-checked-children="否" checked-children="是" :checked="form.is_on_used==1?true:false" @change="changeOnused"/>
				</a-form-model-item>
			</a-form-model>	
		</a-modal>

		<a-modal :title="!formY.id ? '参数添加' :'参数编辑'" :visible="modelYVisible" @ok="handleYOk" @cancel="handleYCancel">
			<a-form-model :key="formYKey" ref="ruleYForm" :model="formY" :rules="rulesY" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="信封存款阈值" prop="env_max">
					<a-input-number :min="1" v-model="formY.env_max" placeholder="请输入信封存入最大数量" style="width: 100%;"/>
				</a-form-model-item>
				<a-form-model-item label="现金存款阈值" prop="cash_max">
					<a-input-number :min="1" v-model="formY.cash_max" placeholder="请输入现金存入最大张数" style="width: 100%;"/>
				</a-form-model-item>
				<a-form-model-item label="点钞预置数" prop="cash_num">
					<a-input-number :min="1" :max="150" v-model="formY.cash_num" placeholder="请输入设备点钞预置数" style="width: 100%;"/>
				</a-form-model-item>
				<a-form-model-item label="信封存款金额" prop="eamount_max">
					<a-input-number :min="0" v-model="formY.eamount_max" placeholder="请输入信封存入金额" style="width: 100%;"/>
				</a-form-model-item>
				<a-form-model-item label="按键使能设置" prop="is_onbutton">
					<a-switch un-checked-children="关" checked-children="开" :checked="formY.is_onbutton==1?true:false" @change="changeButton"/>
				</a-form-model-item>
				<a-form-model-item label="强制关闭设置" prop="is_used">
					<a-switch un-checked-children="关" checked-children="开" :checked="formY.is_used==1?true:false" @change="changeUsed"/>
				</a-form-model-item>
				<a-form-model-item label="是否备份设置" prop="is_backup">
					<a-switch un-checked-children="否" checked-children="是" :checked="formY.is_backup==1?true:false" @change="changeBackup"/>
				</a-form-model-item>
				<a-form-model-item label="是否开启语音" prop="is_music">
					<a-switch un-checked-children="否" checked-children="是" :checked="formY.is_music==1?true:false" @change="changeMusic"/>
				</a-form-model-item>
				<a-form-model-item label="是否开启备注" prop="is_remark">
					<a-switch un-checked-children="否" checked-children="是" :checked="formY.is_remark==1?true:false" @change="changeRemark"/>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	

	const columns = [{
			title: '所属客户',
			dataIndex: 'corp_name',
			key: 'corp_name',
			width: 160,
		},
		{
			title: '网点名称',
			key: 'node_name	',
			dataIndex: 'node_name',
			width: 160,
		},		
		{
			title: '设备名称',
			key: 'name',
			dataIndex: 'name',
			width: 160,
		},
		{
			title: '设备编号',
			key: 'number',
			dataIndex: 'number',
		},
		{
			title: 'Appid',
			key: 'appid',
			dataIndex: 'appid',
		},
		{
			title: 'Secret',
			key: 'secret',
			dataIndex: 'secret',
		},
		{
			title: '锁具SN',
			key: 'snumber',
			dataIndex: 'snumber',
		},
		{
			title: '软件版本',
			key: 'age1',
			scopedSlots: {
				customRender: 'age1'
			},
		},
		{
			title: '预置数/信封/现金',
			key: 'age2',
			scopedSlots: {
				customRender: 'age2'
			},
		},
		{
			title: '传感器状态',
			key: 'isopen',
			scopedSlots: {
				customRender: 'isopen'
			},
		},
		{
			title: '设备是否启用',
			key: 'is_on_used',
			scopedSlots: {
				customRender: 'is_on_used'
			},
		},
		{
			title: '操作',
			key: 'age3',
			width: 260,
			scopedSlots: {
				customRender: 'age3'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				modelYVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					id: 0,	
					type_id: undefined,
					name: '',	
					number: '',	
					corp_id: undefined,
					node_id: undefined,
					lock_id: undefined,
					is_on_used:0,
				},
				formY: {
					id: 0,	
					env_max:0,
					eamount_max:0,
					cash_max:0,
					cash_num:0,
					is_used:0,
					is_onbutton:0,
					is_backup:0,
					is_music:0,
					is_remark:0,
				},
				rules: {
					name: [{
						required: true,
						message: '请输入设备名称',
						trigger: 'blur'
					}],
					number: [{
						required: true,
						message: '请输入设备编号',
						trigger: 'blur'
					}],
					corp_id: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
					type_id: [{
						required: true,
						message: '请选择设备类型',
						trigger: 'change'
					}],
					node_id: [{
						required: true,
						message: '请选择网点',
						trigger: 'change'
					}],
					lock_id: [{
						required: true,
						message: '请选择锁具',
						trigger: 'change'
					}],
				},
				
				rulesY: {
					env_max: [{
						required: true,
						message: '请输入信封存款阈值',
						trigger: 'blur'
					}],
					cash_max: [{
						required: true,
						message: '请输入现金存款阈值',
						trigger: 'blur'
					}],
					cash_num: [{
						required: true,
						message: '请输入设备点钞预置数',
						trigger: 'blur'
					}],
					eamount_max: [{
						required: true,
						message: '请输入现金存款最大金额',
						trigger: 'blur'
					}],
				},

				corp_id: null,
				number: '',
				name: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				formKey: Math.random(),
				formYKey: Math.random(),
				
				
				nodeList: [],
				lockList: [],
				deviceTypelist: [],
				
				statusIndex: 0,
				statusList: [],
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {...corpTreeList}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
			http({
				method: 'post',
				url: '/api.device.type.treelist/',
				data: {
				}
			}).then((res) => {
				this.deviceTypelist = res.data
			})
			http({
				method: 'post',
				url: '/api.device.lock.treelist/',
				data: {
				}
			}).then((res) => {
				this.lockList = res.data
			})			
		},
		methods: {
			tabCallback (e) {
				this.tablePage = 1
				this.statusIndex = e
				this.getTable()
			},
			getStatusList () {
				http({
					method: 'post',
					url: '/api.device.statuscount/',
					data: {
						corp_id: this.corp_id,
						name: this.name,
						number: this.number,
					}
				}).then((res) => {
					this.statusList = res.data
				})
			},
			customRequest (data) {
				http({
					method: 'post',
					url: '/api.config.upload.image/',
					data: {
						file: data.file
					}
				}).then((res) => {
					
				})
			},
			changeUsed(e){
				if(e){
					this.$nextTick(()=>{
						this.formY.is_used = 1
					})
				}else{
					this.$nextTick(()=>{
						this.formY.is_used = 0
					})
				}
			},
			changeBackup(e){
				if(e){
					this.$nextTick(()=>{
						this.formY.is_backup = 1
					})
				}else{
					this.$nextTick(()=>{
						this.formY.is_backup = 0
					})
				}
			},
			changeRemark(e){
				if(e){
					this.$nextTick(()=>{
						this.formY.is_remark = 1
					})
				}else{
					this.$nextTick(()=>{
						this.formY.is_remark = 0
					})
				}
			},
			changeButton(e){
				if(e){
					this.$nextTick(()=>{
						this.formY.is_onbutton = 1
					})
				}else{
					this.$nextTick(()=>{
						this.formY.is_onbutton = 0
					})
				}
			},
			changeMusic(e){
				if(e){
					this.$nextTick(()=>{
						this.formY.is_music = 1
					})
				}else{
					this.$nextTick(()=>{
						this.formY.is_music = 0
					})
				}
			},
			changeOnused(e){
				if(e){
					this.$nextTick(()=>{
						this.form.is_on_used = 1
					})
				}else{
					this.$nextTick(()=>{
						this.form.is_on_used= 0
					})
				}
			},
			handleSubmit(e) {
			},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data
				this.getFormData()
			},
			getFormData (corp_id) {
				http({
					method: 'post',
					url: '/api.corp.node.treelist/',
					data: {
						corp_id: corp_id || this.form.corp_id
					}
				}).then((res) => {
					this.nodeList = res.data
				})
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.number= ''
					this.name= ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.device.list/',
					data: {
						name: this.name,
						number: this.number,
						page: this.tablePage,
						corp_id: this.corp_id,
						status: this.statusIndex
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
				
				this.getStatusList()
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			open(){
				this.$confirm({
					title: '是否确定启用当前列表终端？',
					okText: '确定',
					okType: 'primary',
					cancelText: '取消',
					onOk: () => {
						http({
							method: 'post',
							url: '/api.device.toopen/',
							data: {
								name: this.name,
								number: this.number,
								corp_id: this.corp_id
							}
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('启用命令推送成功');
								this.getTable()
							} else {
								this.$message.warning(res.message);
							}
						})
					},
					onCancel: () => {

					},
				});
			},
			close(){
				this.$confirm({
					title: '是否确定暂停当前列表终端？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						http({
							method: 'post',
							url: '/api.device.toclose/',
							data: {
								name: this.name,
								number: this.number,
								corp_id: this.corp_id
							}
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('暂停命令推送成功');
								this.getTable()
							} else {
								this.$message.warning(res.message);
							}
						})
					},
					onCancel: () => {

					},
				});
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.device.del/',
					data: {
						id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除设备成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			clean(id) {
				http({
					method: 'post',
					url: '/api.device.clean/',
					data: {
						id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('解绑设备成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			toOpen(id,is_open) {
				http({
					method: 'post',
					url: '/api.device.isopen/',
					data: {
						id: id,
						is_open: is_open,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('设备参数设置成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				this.getFormData(data.corp_id)
				this.form = {
					...data,
					corp_id: data.corp_id,
				}
				this.modelVisible = true;
				this.formKey = Math.random()
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						delete newData.corp_name
						delete newData.version
						delete newData.is_open
						delete newData.status
						delete newData.status_txt
						delete newData.snumber
						delete newData.node_name
						delete newData.secret
						delete newData.appid
						delete newData.env_max
						delete newData.cash_max
						delete newData.eamount_max
						delete newData.cash_num
						delete newData.is_used
						delete newData.is_onbutton
						delete newData.is_backup
						delete newData.is_music
						delete newData.is_remark
						delete newData.vname
						if (typeof newData.corp_id == 'object') {
							newData.corp_id = newData.corp_id[newData.corp_id.length - 1]
						}
						http({
							method: 'post',
							url: '/api.device.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.id) {
									this.$message.success('编辑设备信息成功');
								} else {
									this.$message.success('添加设备成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});
			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					id: 0,
					type_id: undefined,
					name: '',	
					number: '',	
					corp_id: undefined,
					node_id: undefined,
					lock_id: undefined,
					is_on_used: 1,
				}
				this.formKey = Math.random()
			},
			
			editY(data) {
				this.formY.id = data.id
				this.formY.env_max = data.env_max
				this.formY.eamount_max = data.eamount_max
				this.formY.cash_max = data.cash_max
				this.formY.cash_num = data.cash_num
				this.formY.is_used = data.is_used
				this.formY.is_onbutton = data.is_onbutton
				this.formY.is_backup = data.is_backup
				this.formY.is_music = data.is_music
				this.formY.is_remark = data.is_remark
				this.modelYVisible = true;
				this.formYKey = Math.random()
			},
			handleYOk() {
				this.$refs.ruleYForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.formY
						}
						http({
							method: 'post',
							url: '/api.device.addset/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.formY.id) {
									this.$message.success('设备参数设置成功');
								} else {
									this.$message.success('添加设备参数成功');
								}
								this.getTable()
								this.modelYVisible = false;
								this.initYFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});
			},
			handleYCancel() {
				this.modelYVisible = false;
				this.initYFormData()
			},
			initYFormData() {
				this.formY = {
					id: 0,
					env_max: 0,	
					eamount_max:0,
					cash_max: 0,	
					cash_num:0,
					is_used:0,
					is_onbutton:0,
					is_backup:0,
					is_music:0,
					is_remark:0,
				}
				this.formYKey = Math.random()
			},
		},
	};
</script>

<style lang="less" scoped>
	/deep/ .ant-tabs-bar {
		    margin-bottom: 0px;
		    border-bottom: none;
	}
	
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content:flex-end;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
