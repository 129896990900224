<template>
	<div class="content-box-div">
		
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" :treeCheckStrictly="true" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						@change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>
		
		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="add" icon="plus-circle">
				添加
			</a-button>
		</div>
		
		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="start_time" slot-scope="text, record">
					<div>{{record.start_time}} <span style="margin: 0 4px;">~</span> {{record.end_time}}</div>
				</div>
				<div slot="age6" slot-scope="text, record">
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.nid)">
						<template slot="title">
							<p>是否确定删除该配置？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>
		
		<!-- <div class="tabbox">
			<a-tabs type="card">
				<a-tab-pane key="1" tab="日结备注配置">
					<a-spin :spinning="remarkLoading">
						<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }">
							<a-form-item label="备注配置">
								<a-button style="margin-right: 14px;">{{remarkValue || '暂未配置'}}</a-button>
								<a-button type="primary"
									@click="saveRemarkConfig">{{remarkValue ? '重新配置' : '备注配置'}}</a-button>
							</a-form-item>
						</a-form>
					</a-spin>
				</a-tab-pane>
			</a-tabs>
		</div> -->

		<a-modal :dialogStyle="{top: '60px'}" :title="detailData.cdr_id == -1 ? '添加通知' : '编辑通知'" :visible="remarkvisible" @ok="handleOk" @cancel="handleCancel">
			<div>
				<div class="fo_input">
					<div class="fo_label">选择客户：</div>
					<div class="fo_value">
						<a-tree-select v-model="detailData.corp_id" @change="chcorpChange" style="width: 340px;"
							:dropdown-style="{ maxHeight: '340px', overflow: 'auto' }" :tree-data="corpList"
							placeholder="请选择客户"
							:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
						</a-tree-select>
					</div>
				</div>
				<div class="fo_input" v-if="detailData.corp_id" >
					<div class="fo_label">选择网点：</div>
					<div class="fo_value">
						<a-select mode="multiple" style="width: 340px" :filter-option="filterOption" @change="chnodeChange" v-model="detailData.node_id_list" placeholder="请选择网点" >
						   <a-select-option :value="item.node_id" v-for="(item, index) in nodeList" :key="index">
						       {{item.name}}
						   </a-select-option>
						</a-select>
					</div>
				</div>
				<div class="fo_input">
					<div class="fo_label">通知标题：</div>
					<div class="fo_value">
						<a-input style="width: 340px;" v-model="detailData.title" placeholder="请输入标题" />
					</div>
				</div>
				<div class="fo_input">
					<div class="fo_label">通知内容：</div>
					<div class="fo_value">
						<a-textarea placeholder="请输入内容"  style="width: 340px;" v-model="detailData.content" :rows="4" />
					</div>
				</div>
				<div class="fo_input">
					<div class="fo_label">有效时间：</div>
					<div class="fo_value">
						<a-range-picker
							format="YYYY-MM-DD HH:mm:ss"
							v-model="detailData.start_time"
							:show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
							style="width: 340px;"
							placeholder="请选择开始转账时间"
						/>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'
	export default {
		data() {
			return {
				moment,
				remarkValue: '',
				remarkValueList: [],
				lodRemarkObject: {},
				remarkList: [],
				remarkvisible: false,
				checkedList: [],
				remarkLoading: true,
				
				corp_id: null,
				tablePage: 1,
				corpList: [],
				tableData: [],
				tableTotal: 0,
				btnIds: [],
				columns: [
					{
						title: '标题',
						dataIndex: 'title',
						key: 'title',
						width: 200,
					},
					{
						title: '内容',
						key: 'content',
						dataIndex: 'content',
						scopedSlots: {
							customRender: 'content'
						},
					},
					{
						title: '有效时间',
						key: 'start_time',
						dataIndex: 'start_time',
						scopedSlots: {
							customRender: 'start_time'
						},
					},
					{
						title: '操作',
						key: 'age6',
						width: 150,
						scopedSlots: {
							customRender: 'age6'
						},
					},
				],
				detailData: {
					nid: 0,
					node_id_list: [],
					content: '',
					title: '',
					start_time: '',
					corp_id: null
				},
				nameObjects: {},
				nodeList: []
			}
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				this.getTable()
			})
		},
		methods: {
			chcorpChange (data) {
				this.detailData.corp_id = data
				this.getFormList()
			},
			getFormList (corpid) {
				http({
					method: 'post',
					url: '/api.corp.node.alllist/',
					data: {
						corp_id: corpid ? corpid : this.detailData.corp_id
					}
				}).then((res) => {
					this.nodeList = res.data
				})
			},
			chnodeChange (data) {
				// this.form.node_id = data
				// this.getNodeFormList()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.notice.del/',
					data: {
						nid: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			add () {
				this.remarkList.forEach((v) => {
					v.check = false
				})
				this.detailData = {
					nid: 0,
					node_id_list: [],
					content: '',
					title: '',
					start_time: '',
					corp_id: null
				}
				this.checkedList = []
				this.remarkvisible = true
			},
			edit (data) {
				let names = data.content.split(data.space)
				let contents = []
				names.forEach((v) => {
					contents.push(this.nameObjects[v])
				})
				this.checkedList = contents
				this.remarkList.forEach((v) => {
					if (contents.includes(v.value)) {
						v.check = true
					} else {
						v.check = false
					}
				})
				this.detailData = data
				this.remarkvisible = true
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.corp_name = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.notice.list/',
					data: {
						page: this.tablePage,
						corp_id: this.corp_id || 0
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			corpChange(data) {
				this.corp_id = data
			},
			
			getRemarkInit () {
				// this.remarkLoading = true
				 http({
				 	method: 'post',
				 	url: '/api.config.remark.params/',
				 	data: {}
				 }).then((res) => {
				 	this.lodRemarkObject = res.data
					let nameObjects = {}
				 	let remarkList = []
				 	for (let key in res.data) {
				 		remarkList.push({
				 			value: key,
				 			label: res.data[key],
				 			check: false
				 		})
						nameObjects[res.data[key]] = key 
				 	}
				 	this.remarkList = remarkList
					this.nameObjects = nameObjects
				 	// http({
				 	// 	method: 'post',
				 	// 	url: '/api.config.remark.corp/',
				 	// 	data: {}
				 	// }).then((resData) => {
				 	// 	let remarkStr = []
				 	// 	if (resData.data) {
				 	// 		remarkStr = resData.data.split(',')
				 	// 	}
				 	// 	let remarkValue = []
				 	// 	let remarkList = []
				 	// 	remarkStr.forEach((item) => {
				 	// 		remarkList.push({
				 	// 			type: item,
				 	// 			name: res.data[item]
				 	// 		})
				 	// 		remarkValue.push(res.data[item])
				 	// 	})
				 	// 	this.remarkValue = remarkValue.join(' - ')
						// this.remarkLoading = false
				 	// })
				 })
			 },
			deleteRemark (value) {
				this.checkedList.splice(this.checkedList.indexOf(value), 1);
				this.remarkList.forEach((v) => {
					if (v.value == value) {
						v.check = false
					}
				})
			},
			getRemarkName(type) {
				return this.lodRemarkObject[type]
			},
			onChange(e) {
				let value = e.target.value.value
				if (this.checkedList.includes(value)) {
					this.checkedList.splice(this.checkedList.indexOf(value), 1);
					this.remarkList.forEach((v) => {
						if (v.value == value) {
							v.check = false
						}
					})
				} else {
					this.remarkList.forEach((v) => {
						if (v.value == value) {
							v.check = true
						}
					})
					this.checkedList.push(value)
				}
			},
			saveRemarkConfig() {
				this.remarkvisible = true
				
			},
			handleOk() {
				if (!this.detailData.corp_id) {
					this.$message.error('请选择客户');
					return
				}
				if (this.detailData.node_id_list && this.detailData.node_id_list.length == 0) {
					this.$message.error('请选择网点');
					return
				}
				if (!this.detailData.title) {
					this.$message.error('请输入标题');
					return
				}
				if (!this.detailData.content) {
					this.$message.error('请输入内容');
					return
				}
				if (!this.detailData.start_time) {
					this.$message.error('请选择有效时间');
					return
				}
				let data = {
					nid: this.detailData.nid,
					title: this.detailData.title,
					content: this.detailData.content,
					start_time: moment(this.detailData.start_time[0]).format('YYYY-MM-DD HH:mm:ss'),
					end_time: moment(this.detailData.start_time[1]).format('YYYY-MM-DD HH:mm:ss'),
					node_id_list: this.detailData.node_id_list.join(','),
					corp_id: this.detailData.corp_id,
				}
				if (this.detailData.nid > 0) {
					data['nid'] = this.detailData.nid
				} else {
					data['nid'] = 0
				}
				http({
					method: 'post',
					url: '/api.notice.add/',
					data: data
				}).then((res) => {
					if (res.code == 200) {
						this.getTable()
						this.$message.success('添加成功！');
						this.checkedList = []
						this.remarkvisible = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			handleCancel () {
				this.checkedList = []
				this.remarkvisible = false
			}
		}
	}
</script>

<style scoped lang="less">
	.fo_input {
		display: flex;
		min-height: 32px;
		margin-bottom: 14px;
		.fo_label {
			width: 100px;
			line-height: 32px;
			padding-right: 10px;
			height: 32px;
			text-align: right;
			// &:before {
			// 	width: 3px;
			// 	height: 14px;
			// 	background-color: #3473FF;
			// 	content: "";
			// 	margin-right: 4px;
			// }
		}
		.fo_value {
			
		}
	}
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}
	
	.filter-box {
		padding: 20px;
	}
	
	.btns10 {
		margin-left: 30px;
	
		button {
			margin-right: 10px;
		}
	}
	
	.table-box {
		padding: 0px 20px;
	}
	
	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
	.tips {
		opacity: 0.6;
		    font-size: 12px;
		    width: 64px;
		    text-align: center;
	}
	.content-box-div {
		/* min-height: 400px; */
	}

	.tabbox {
		padding: 20px;
	}
	.check_remark {
		display: flex;
		flex-wrap: wrap;
	}
	.check_remark>div {
		width: 48%;
		margin-bottom: 10px;
	}

	.title_remark {
		height: 40px;
		display: flex;
		align-items: center;
		font-weight: 700;
		font-size: 14px;

		&:before {
			content: "";
			width: 4px;
			height: 16px;
			background-color: #3473FF;
			margin-right: 6px;
		}
	}

	.list_remark {
		min-height: 120px;
		padding-left: 10px;
		.item_remark {
			margin-bottom: 4px;
			height: 30px;
			display: flex;
			align-items: center;
			
			&:nth-child(1) {
				.index {
					background-color: #ff1212;
					color: #fff;
				}
			}
			&:nth-child(2) {
				.index {
					background-color: #21c9f6;
					color: #fff;
				}
			}
			&:nth-child(3) {
				.index {
					background-color: #ff7619;
					color: #fff;
				}
			}

			.index {
				width: 20px;
				height: 20px;
				background-color: #e1dada;
				border-radius: 100px;
				margin-right: 6px;
				display: flex;
				color: #333;
				align-items: center;
				justify-content: center;
			}

			.text {
				width: 220px;
			}

			.close {
				cursor: pointer;
			}
		}
	}
</style>